import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import billboard01 from '../../images/billboardslider-01-min.jpg'
// import billboard02 from '../../images/billboardslider-02-min.jpg'
// import billboard03 from '../../images/billboardslider-03-min.jpg'
// import billboard04 from '../../images/billboardslider-04-min.jpg'
import billboard05 from '../../images/billboardslider-05-min.jpg'
// import billboard06 from '../../images/billboardslider-06-min.jpg'
// import billboard07 from '../../images/billboardslider-07-min.jpg'
// import billboard08 from '../../images/billboardslider-08-min.jpg'
// import billboard09 from '../../images/billboardslider-09-min.jpg'
// import billboard10 from '../../images/billboardslider-10-min.jpg'
// import billboard11 from '../../images/billboardslider-11-min.jpg'
import billboard12 from '../../images/billboardslider-12-min.jpg'
// import billboard13 from '../../images/billboardslider-13-min.jpg'

export default class BillboardSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={billboard01} className="img-fluid" alt="billboard" />
				</div>

				<div>
					<img src={billboard05} className="img-fluid" alt="billboard" />
				</div>

				<div>
					<img src={billboard12} className="img-fluid" alt="billboard" />
				</div>
			</Swiper>
		)
	}
}
