import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'

import cstoreslider01 from '../../images/cstoreslider-01-min.jpg'
// import cstoreslider02 from '../../images/cstoreslider-02-min.jpg'
import cstoreslider03 from '../../images/cstoreslider-03-min.jpg'
import cstoreslider04 from '../../images/cstoreslider-04-min.jpg'
// import cstoreslider05 from '../../images/cstoreslider-05-min.jpg'
// import cstoreslider06 from '../../images/cstoreslider-06-min.jpg'
// import cstoreslider07 from '../../images/cstoreslider-07-min.jpg'
// import cstoreslider08 from '../../images/cstoreslider-08-min.jpg'
// import cstoreslider09 from '../../images/cstoreslider-09-min.jpg'
// import cstoreslider10 from '../../images/cstoreslider-10-min.jpg'
import cstoreslider11 from '../../images/cstoreslider-11-min.jpg'
import cstoreslider12 from '../../images/cstoreslider-12-min.jpg'
// import cstoreslider13 from '../../images/cstoreslider-13-min.jpg'
// import cstoreslider14 from '../../images/cstoreslider-14-min.jpg'
import cstoreslider15 from '../../images/cstoreslider-15-min.jpg'
import cstoreslider16 from '../../images/cstoreslider-16-min.jpg'
import cstoreslider17 from '../../images/cstoreslider-17-min.jpg'
// import cstoreslider18 from '../../images/cstoreslider-18-min.jpg'
// import cstoreslider19 from '../../images/cstoreslider-19-min.jpg'
import cstoreslider20 from '../../images/cstoreslider-20-min.jpg'
// import cstoreslider21 from '../../images/cstoreslider-21-min.jpg'
// import cstoreslider22 from '../../images/cstoreslider-22-min.jpg'
// import cstoreslider23 from '../../images/cstoreslider-23-min.jpg'
// import cstoreslider24 from '../../images/cstoreslider-24-min.jpg'

export default class CStoreSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img
						src={cstoreslider01}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>

				<div>
					<img
						src={cstoreslider03}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider04}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>

				<div>
					<img
						src={cstoreslider11}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider12}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>

				<div>
					<img
						src={cstoreslider15}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider16}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider17}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>

				<div>
					<img
						src={cstoreslider20}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				{/*
				<div>
					<img
						src={cstoreslider02}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider05}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider06}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider07}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider08}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider09}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider10}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider01}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>  			
				<div>
					<img
						src={cstoreslider13}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider14}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div>
				<div>
					<img
						src={cstoreslider18}
						className="img-fluid"
						alt="C Store Slider"
					/>
				</div><div>
          <img
            src={cstoreslider19}
            className="img-fluid"
            alt="C Store Slider"
          />
        </div>
				<div>
          <img
            src={cstoreslider21}
            className="img-fluid"
            alt="C Store Slider"
          />
        </div>
        <div>
          <img
            src={cstoreslider22}
            className="img-fluid"
            alt="C Store Slider"
          />
        </div>
        <div>
          <img
            src={cstoreslider23}
            className="img-fluid"
            alt="C Store Slider"
          />
        </div>
        <div>
          <img
            src={cstoreslider24}
            className="img-fluid"
            alt="C Store Slider"
          />
        </div>*/}
			</Swiper>
		)
	}
}
