import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import laundromatslide01 from '../../images/laundromatslider-01-min.jpg'
import laundromatslide02 from '../../images/laundromatslider-02-min.jpg'
import laundromatslide03 from '../../images/laundromatslider-03-min.jpg'
// import laundromatslide04 from '../../images/laundromatslider-04-min.jpg'
// import laundromatslide05 from '../../images/laundromatslider-05-min.jpg'
import laundromatslide06 from '../../images/laundromatslider-06-min.jpg'
// import laundromatslide07 from '../../images/laundromatslider-07-min.jpg'
import laundromatslide08 from '../../images/laundromatslider-08-min.jpg'
// import laundromatslide09 from '../../images/laundromatslider-09-min.jpg'
import laundromatslide10 from '../../images/laundromatslider-10-min.jpg'

export default class LaundromatSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={laundromatslide01} className="img-fluid" alt="laundromat" />
				</div>
				<div>
					<img src={laundromatslide02} className="img-fluid" alt="laundromat" />
				</div>
				<div>
					<img src={laundromatslide03} className="img-fluid" alt="laundromat" />
				</div>

				<div>
					<img src={laundromatslide06} className="img-fluid" alt="laundromat" />
				</div>

				<div>
					<img src={laundromatslide08} className="img-fluid" alt="laundromat" />
				</div>

				<div>
					<img src={laundromatslide10} className="img-fluid" alt="laundromat" />
				</div>
				{/*  <div>
          <img src={laundromatslide04} className="img-fluid" alt="laundromat" />
        </div>
        <div>
          <img src={laundromatslide05} className="img-fluid" alt="laundromat" />
        </div>
           <div>
          <img src={laundromatslide07} className="img-fluid" alt="laundromat" />
        </div> 
        <div>
          <img src={laundromatslide09} className="img-fluid" alt="laundromat" />
        </div>*/}
			</Swiper>
		)
	}
}
