import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
// import foodstandslide01 from '../../images/foodstandslide-01-min.jpg'
import foodstandslide02 from '../../images/foodstandslide-02-min.jpg'
import foodstandslide03 from '../../images/foodstandslide-03-min.jpg'
// import foodstandslide04 from '../../images/foodstandslide-04-min.jpg'
// import foodstandslide05 from '../../images/foodstandslide-05-min.jpg'
import foodstandslide06 from '../../images/foodstandslide-06-min.jpg'
// import foodstandslide07 from '../../images/foodstandslide-07-min.jpg'
// import foodstandslide08 from '../../images/foodstandslide-08-min.jpg'
// import foodstandslide09 from '../../images/foodstandslide-09-min.jpg'
import foodstandslide10 from '../../images/foodstandslide-10-min.jpg'
// import foodstandslide11 from '../../images/foodstandslide-11-min.jpg'
// import foodstandslide12 from '../../images/foodstandslide-12-min.jpg'
import foodstandslide13 from '../../images/foodstandslide-13-min.jpg'
import foodstandslide14 from '../../images/foodstandslide-14-min.jpg'
// import foodstandslide15 from '../../images/foodstandslide-15-min.jpg'

export default class FoodStandSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={foodstandslide02} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide03} className="img-fluid" alt="foodstand" />
				</div>

				<div>
					<img src={foodstandslide06} className="img-fluid" alt="foodstand" />
				</div>

				<div>
					<img src={foodstandslide10} className="img-fluid" alt="foodstand" />
				</div>

				<div>
					<img src={foodstandslide13} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide14} className="img-fluid" alt="foodstand" />
				</div>
				{/* 
					<div>
					<img src={foodstandslide01} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide04} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide05} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide07} className="img-fluid" alt="foodstand" />
				</div>
				<div>
					<img src={foodstandslide08} className="img-fluid" alt="foodstand" />
				</div> <div>
          <img src={foodstandslide09} className="img-fluid" alt="foodstand" />
        </div>
				 <div>
          <img src={foodstandslide11} className="img-fluid" alt="foodstand" />
        </div>
        <div>
          <img src={foodstandslide12} className="img-fluid" alt="foodstand" />
        </div>
				<div>
          <img src={foodstandslide15} className="img-fluid" alt="foodstand" />
        </div>*/}
			</Swiper>
		)
	}
}
