import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import salonslide01 from '../../images/salonslider-01-min.jpg'
import salonslide02 from '../../images/salonslider-02-min.jpg'
// import salonslide03 from '../../images/salonslider-03-min.jpg'
// import salonslide04 from '../../images/salonslider-04-min.jpg'
import salonslide05 from '../../images/salonslider-05-min.jpg'
// import salonslide06 from '../../images/salonslider-06-min.jpg'
import salonslide07 from '../../images/salonslider-07-min.jpg'
// import salonslide08 from '../../images/salonslider-08-min.jpg'

export default class SalonSlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={salonslide01} className="img-fluid" alt="salon" />
				</div>
				<div>
					<img src={salonslide02} className="img-fluid" alt="salon" />
				</div>

				<div>
					<img src={salonslide05} className="img-fluid" alt="salon" />
				</div>

				<div>
					<img src={salonslide07} className="img-fluid" alt="salon" />
				</div>

				{/*
					<div>
					<img src={salonslide03} className="img-fluid" alt="salon" />
				</div>
				<div>
					<img src={salonslide04} className="img-fluid" alt="salon" />
				</div> 
				<div>
					<img src={salonslide06} className="img-fluid" alt="salon" />
				</div>
				<div>
          <img src={salonslide08} className="img-fluid" alt="salon" />
        </div>*/}
			</Swiper>
		)
	}
}
