import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/lib/styles/css/swiper.css'
import bakeryslide05 from '../../images/bakeryslide-05-min.jpg'
import bakeryslide06 from '../../images/bakeryslide-06-min.jpg'
import bakeryslide07 from '../../images/bakeryslide-07-min.jpg'

export default class BakerySlider extends Component {
	render() {
		const params = {
			loop: true,
			effect: 'fade',
			pagination: {
				el: '.swiper-pagination',
			},
			clickable: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}

		return (
			<Swiper {...params}>
				<div>
					<img src={bakeryslide05} className="img-fluid" alt="bakery" />
				</div>
				<div>
					<img src={bakeryslide06} className="img-fluid" alt="bakery" />
				</div>
				<div>
					<img src={bakeryslide07} className="img-fluid" alt="bakery" />
				</div>
			</Swiper>
		)
	}
}
