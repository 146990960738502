import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
// import HeroSlider from '../components/hero/storeslider.js'
import BillboardsSlider from '../components/hero/billboardsSlider.js'
import CStoreSlider from '../components/hero/cstoreSlider.js'
import LaundromatSlider from '../components/hero/laundromatSlider.js'
import FoodStandSlider from '../components/hero/foodStandSlider.js'
import SalonSlider from '../components/hero/salonSlider.js'
import BakerySlider from '../components/hero/bakerySlider.js'
import trimexOutdoor from '../images/maptruck-v3.png'
class storeFrontAdvertising extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Storefront Urban and Rural Advertising' }}>
        {/*<HeroSlider />*/}

        {/* START OF HEADER */}
        <section id="title">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <h1 className="heading-title">
                  LOCAL PLACE-BASED ADVERTISING <br />
                  ON A NATIONAL SCALE
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/* END OF HEADER */}

        {/* START OF STOREFRONT NETWORK */}
        <section id="lunchtruck">
          <div className="container">
            <div className="text-center">
              <h2 className="heading-title">
                Trimex Outdoor’s <em>IN-STORE ADVERTISING NETWORK</em> is
                available in every U.S. market and DMA, we can build out custom
                networks to fit any campaign with 100% POP Provided!
              </h2>
              <img
                src={trimexOutdoor}
                alt="trimex market"
                className="img-fluid img-border"
              />
              <h3 className="section-p mb-0">
                <strong>
                  In-Store Advertising with the Biggest Impact at the Lowest
                  Rates (only $3-6 per day)!
                </strong>
              </h3>
              <p className="section-p">
                We offer custom showings targeting all types of venues,
                demographics, including Hispanic, Asian, AA, AI, Middle East,
                LGTBQ, Millennials, General Market, Families, Teens, and more…
              </p>
            </div>
            <div className="row section-p">
              <div className="col-md-6">
                <ul className="styled-list">
                  <li>Large Grocery Supermarket Stores</li>
                  <li>C-Stores</li>
                  <li>
                    Ethnic Grocery i.e., Carnicerias Y Rancho Markets, Halal
                    Meat, Asian etc.
                  </li>
                  <li>Mom and Pop Markets</li>
                  <li>Food Trucks</li>
                  <li>Restaurants / Eateries / Diners</li>
                  <li>Tortilla Y Panderia’s/Bakeries</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="styled-list">
                  <li>Laundromats</li>
                  <li>Hair and Nail Salons – Barber Shops Too!</li>
                  <li>Coffee Shops</li>
                  <li>Thrift Stores</li>
                  <li>
                    Automotive – Carwashes, Mechanics, Smog & Oil Change,
                    Fueling Stations
                  </li>
                  <li>Pharmacies & Drug Stores</li>
                </ul>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <h2 className="section-title">
                  OUR STANDARD IN-STORE ADVERTISING CAMPAIGNS FEATURE 3 KEY
                  TOUCHPOINTS:
                </h2>
                <h3 className="mb-4">
                  <ol>
                    <li className="mb-2">
                      <p className="text-center">
                        Large 60" x 30" or traditional One Sheet size 30” x 46”
                        Exterior Banner Sign
                      </p>
                    </li>
                    <li className="mb-2">
                      <p className="text-center">
                        8" x 8" Interior Pop-Up Easel Display (by register)
                      </p>
                    </li>
                    <li className="mb-2">
                      <p className="text-center">
                        4” x 8” Take-One Box (printed brochures included)
                      </p>
                    </li>
                  </ol>
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* END OF STOREFRONT NETWORK */}

        {/* START OF SLIDERS */}
        <section className="text-center bg-offwhite">
          <div className="container">
            <div className="pb-5">
              <h4 className="text-green">
                Grocery Store & Carnicerias Examples
              </h4>
              <BillboardsSlider />
            </div>
            <div className="pb-5">
              <h4 className="text-green pb-1">
                C-Store and Mom & Pop Market Examples
              </h4>
              <CStoreSlider />
            </div>
            <div className="pb-5">
              <h4 className="text-green">Laundromats</h4>
              <div className="row">
                <div className="col">
                  <LaundromatSlider />
                </div>
              </div>
            </div>
            <div className="pb-5">
              <h4 className="text-green">
                Beauty/Hair/Nail Salon Examples AA & Women Targeting
              </h4>
              <div className="row">
                <div className="col">
                  <SalonSlider />
                </div>
              </div>
            </div>
            <div className="pb-5">
              <h4 className="text-green">Bakeries Y Panaderia’s</h4>
              <div className="row">
                <div className="col-md-12">
                  <BakerySlider />
                </div>
              </div>
            </div>
            <div className="pb-5">
              <h4 className="text-green">Food Stands & Eateries</h4>
              <div className="row">
                <div className="col">
                  <FoodStandSlider />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END OF SLIDERS */}
      </Layout>
    )
  }
}
export default storeFrontAdvertising
